import React from "react";

export const Service = (props) => {
    return (
        <div id="service" className="text-center">
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title">
                    <h2>Services</h2>
                </div>
                <div className="row">
                    {props.data
                        ? props.data.services.map((d, i) => (
                            <div key={`${d.title}-${i}`} className="col-xs-12 col-md-6">
                                {" "}\
                                <h3>{d.title}</h3>
                                <p>{d.paragraph}</p>
                                <hr />

                                <ul>
                                    {d.features.map((f, i) => (
                                        <li key={`${f}-${i}`}>
                                            <p className="fw-bold">{f.key}</p>

                                            <p>{f.description}</p>
                                            <hr />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))
                        : "Loading..."}
                </div>
            </div>
        </div>
    );
};
