import React from "react";

export const Team = (props) => {
    return (
        <div id="team" className="text-center">
            <div className="container">
                <div className="col-md-12 section-title">
                    <h2>Meet the Team</h2>
                </div>
                {props.data
                    ? props.data.map((d, i) => (
                        <div id="row" className="row">
                            <div key={`${d.name}-${i}`} className="col-md-3 col-sm-12 team">
                                <div className="thumbnail">
                                    {" "}
                                    <img src={d.img} alt="..." className="team-img"/>
                                    <div className="caption">
                                        <h4>{d.name}</h4>
                                        <p>{d.job}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12 team-bio">
                                {d.bio.map((p, i) => (
                                    <p key={`${d.name}-${i}`}>{p}</p>
                                ))}
                            </div>
                            <hr className="col-md-12 col-lg-12 col-sm-12`"></hr>
                        </div>
                    ))
                    : "loading"}
            </div>
        </div>
    );
};
